<template>
    <ka-auth-page v-if="formState === 'SUCCESS'">
        <div class="page-content page-grid">
            <h1 class="tw-heading-1">{{ $t("global.titles.all_done") }}</h1>
            <p>
                {{ $t("auth.descriptions.check_confirmation_email") }}
            </p>
            <router-link :to="{ name: 'auth-signin', params: { locale } }" class="kod-link-primary">
                {{ $t("auth.actions.sign_in_simple") }}
            </router-link>
        </div>
    </ka-auth-page>

    <ka-auth-page v-else>
        <form @submit.prevent="submit" class="page-content page-grid">
            <h1 class="tw-heading-1">{{ $t("auth.titles.create_account") }}</h1>
            <ka-sign-in-with-providers signUp />
            <div class="form-inputs">
                <v-row>
                    <v-col>
                        <div class="name-grid">
                            <k-input v-model="firstName" :title="$t('global.inputs.first_name')" :invalid="submitted && !isValidField('firstName')" />
                            <k-input v-model="lastName" :title="$t('global.inputs.last_name')" :invalid="submitted && !isValidField('lastName')" />
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <k-input
                            v-model="email"
                            type="email"
                            :title="$t('global.inputs.email')"
                            :invalid="submitted && !isValidField('email')"
                            name="username"
                            autocomplete="username"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <k-password
                            v-model="password"
                            :placeholder="$t('global.form_rules.length_6')"
                            :title="$t('global.inputs.password')"
                            :invalid="submitted && !isValidField('password')"
                            name="new-password"
                            autocomplete="new-password"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <k-gdpr
                            class="mt-2"
                            v-model="gdpr"
                            :showNewsletter="false"
                            :invalid="submitted && !isValidField('gdpr')"
                            :gdprTooltip="$t('gdpr.descriptions.register_account_tooltip')"
                        />
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col>
                        <v-expand-transition>
                            <div v-show="formState === 'ERROR'" class="mb-8 form-error-message">
                                <p>{{ $t(formStateMessage) }}</p>
                            </div>
                        </v-expand-transition>

                        <div>
                            <k-button color="primary" class="tw-w-full sm:tw-w-auto" :loading="formState === 'PENDING'" type="submit">
                                {{ $t("auth.actions.create_account") }}
                            </k-button>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col>
                        <p>
                            {{ $t("auth.descriptions.already_have_account") }}
                            <router-link :to="{ name: 'auth-signin', params: { locale } }" class="kod-link-primary">
                                {{ $t("auth.actions.sign_in_here") }}
                            </router-link>
                        </p>
                    </v-col>
                </v-row>
            </div>
        </form>
    </ka-auth-page>
</template>

<script>
import KaAuthPage from "./ka-auth-page";
import responsiveMixin from "./../responsiveMixin";
import KaSignInWithProviders from "./ka-sign-in-with-providers";
import { getRouteLocale } from "@web/constants/language";

export default {
    mixins: [responsiveMixin],
    components: {
        KaAuthPage,
        KaSignInWithProviders
    },
    data() {
        return {
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            gdpr: null,
            submitted: false,
            showPassword: false
        };
    },
    computed: {
        formState: function() {
            return this.$store.state.AUTH.formState;
        },
        formStateMessage: function() {
            return this.$store.state.AUTH.formStateMessage;
        },
        locale() {
            return getRouteLocale();
        }
    },
    watch: {
        formState(value) {
            if (value === "SUCCESS") {
                window.location.hash = "#registracia-hotovo";
            }
        }
    },
    methods: {
        isValidField(fieldName) {
            switch (fieldName) {
                case "firstName":
                    return typeof this.firstName === "string" && this.firstName.trim().length > 0;
                case "lastName":
                    return typeof this.lastName === "string" && this.lastName.trim().length > 0;
                case "email":
                    return typeof this.email === "string" && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email);
                case "password":
                    return typeof this.password === "string" && this.password.trim().length > 5;
                case "gdpr":
                    return this.gdpr && this.gdpr.gdprValue === true;
            }
        },

        isFormValid: function() {
            let valid = true;
            let message = null;

            if (!this.isValidField("firstName")) {
                valid = false;
                message = this.$t("global.form_rules.first_name_filled");
            } else if (!this.isValidField("lastName")) {
                valid = false;
                message = this.$t("global.form_rules.last_name_filled");
            } else if (!this.isValidField("email")) {
                valid = false;
                message = this.$t("global.form_rules.not_valid_email");
            } else if (!this.isValidField("password")) {
                valid = false;
                message = this.$t("global.form_rules.password_min_six_characters");
            } else if (!this.isValidField("gdpr")) {
                valid = false;
                message = this.$t("global.form_rules.accept_terms_conditions");
            }

            if (!valid && message) {
                this.$store.commit("AUTH/SET_FORM_STATE", {
                    formState: "ERROR",
                    formStateMessage: message
                });
            }

            return valid;
        },
        submit() {
            this.submitted = true;
            if (!this.isFormValid()) {
                return;
            }
            this.$store.dispatch("AUTH/SIGN_UP", {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                password: this.password,
                gdpr: this.gdpr,
                language: this.locale === "en" ? "en" : "sk"
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .v-input__icon .v-icon {
    color: $koderia-grey !important;
}

.page-content {
    width: 22.5rem;
}

.page-grid {
    display: grid;
    gap: 2rem;
}

.name-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
}

.form-inputs {
    position: relative;
    margin-top: -1.25rem;
}

.forgot-password-link {
    color: $koderia-black;
    position: absolute;
    top: 0;
    right: 0;
}

.form-error-message p {
    margin-top: 0 !important;
}
</style>
